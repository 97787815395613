import PlantAnimation from "../../../components/PlantAnimation";
import Question1WhatColor from "../../../components/Question1WhatColor";
import Question1WhatEnneagram from "../../../components/Question1WhatEnneagram";
import Question1WhatMusicYoung from "../../../components/Question1WhatMusicYoung";
import Question1WhatSoothingSound from "../../../components/Question1WhatSoothingSound";
import Question1WhatMusicNow from "../../../components/Question1WhatMusicNow";
import Question1End from "../../../components/Question1End";
import Question1ExcludeTopics from "../../../components/Question1ExcludeTopics";
import Question1PlaylistBeingCreated from "../../../components/Question1PlaylistBeingCreated";
import SoothingSong from "../../../components/SoothingSong";
import Question1WhatKindOfChange from "../../../components/Question1WhatKindOfChange";
import {
  completedQuestionnaireOne,
  completedQuestionnaireThree,
  completedQuestionnaireTwo,
} from "../../questionnaire";
import Question2Intro from "../../../components/Question2Intro";
import Question2HowChangeFeel from "../../../components/Question2HowChangeFeel";
import Question2MakeChange from "../../../components/Question2MakeChange";
import Question2HowMuchChange from "../../../components/Question2HowMuchChange";
import Question2FormativePeriod from "../../../components/Question2FormativePeriod";
import Question2WantThreeThings from "../../../components/Question2WantThreeThings";
import Question2HateThreeThings from "../../../components/Question2HateThreeThings";
import Question2MostAfraid from "../../../components/Question2MostAfraid";
import Question2Dependent from "../../../components/Question2Dependent";
import Question2Ignored from "../../../components/Question2Ignored";
import Question2IdentityIndependence from "../../../components/Question2IdentityIndependence";
import Question2Relationships from "../../../components/Question2Relationships";
import Question2Family from "../../../components/Question2Family";
import Question3Intro from "../../../components/Question3Intro";
import Question3InternalExternal from "../../../components/Question3InternalExternal";
import Question3WantMore from "../../../components/Question3WantMore";
import Question3ActionsValues from "../../../components/Question3ActionsValues";
import Question3Pressing from "../../../components/Question3Pressing";
import Question3Impact from "../../../components/Question3Impact";
import Question3LifeWillBe from "../../../components/Question3LifeWillBe";
import Question3Afraid from "../../../components/Question3Afraid";
import Scene3Intro from "../../../components/Scene3Intro";
import Scene3Story1 from "../../../components/Scene3Story1";
import Scene3Narration from "../../../components/Scene3Narration";
import WordOfEncouragement from "../../../components/WordOfEncouragement";
import ReceivedWord from "../../../components/ReceivedWord";
import InPersonNiceSpendingTimeWithYou from "../../../components/InPersonNiceSpendingTimeWithYou";
import EnterVenue from "../../../components/EnterVenue";
import Scene2Story2 from "../../../components/Scene2Story2";
import Scene2Story1 from "../../../components/Scene2Story1";
import { Step } from "./Paths";
import InPersonLandAcknowledgement from "../../../components/InPersonLandAcknowledgement";
import Goodbye from "../../../components/GoodBye";
import InPersonScene2Intro from "../../../components/InPersonScene2Intro";
import End from "../../../components/End";
import { makeAcousticConfig } from "./PathDefinitions";
import PreparingYourFirstAudioAtHome from "../../../components/PreparingYourFirstAudioAtHome";
import InPersonNarration2 from "../../../components/InPersonNarration2";
import InPersonNarration3 from "../../../components/InPersonNarration3";
import Checkin from "../../../components/Checkin";
import Checkout from "../../../components/Checkout";
import RipPaper from "../../../components/RipPaper";
import HaveYouArrived from "../../../components/HaveYouArrived";
import JewelLight2 from "../../../components/JewelLight2";
import InPersonLandAcknowledgement2 from "../../../components/InPersonLandAcknowledgement2";
import Scene1Story1 from "../../../components/Scene1Story1";
import JewelEffect from "../../../components/JewelEffect";
import EddysStory from "../../../components/EddysStory";
import InPersonNiceSpendingTime2 from "../../../components/InPersonNiceSpendingTime2";

export const getInPersonPathDefinition = (): Step[] => [
  {
    component: <InPersonLandAcknowledgement />,
    name: "Land Acknowledgement",
    allowNext: { allowNextOn: "COMPONENT_DECIDES" },
    acoustics: {
      questionnaireMusic: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <EddysStory />,
    name: "Eddys Story",
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: { audioCompletion: "LandAck", showProgressBar: false },
    },
    acoustics: {
      questionnaireMusic: makeAcousticConfig({ secondsUntilFadeOut: 1 }),
      LandAck: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <InPersonLandAcknowledgement2 />,
    name: "Land Acknowledgement2",
    allowNext: {
      allowNextOn: "COMPONENT_DECIDES",
    },
    acoustics: {
      questionnaireMusic: makeAcousticConfig({ volume: 0.1 }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Question1WhatColor />,
    name: "Q1. What color",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1WhatEnneagram />,
    name: "Q1. What enegram",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1WhatMusicYoung />,
    name: "Q1. What music young",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1WhatSoothingSound />,
    name: "Q1. What soothing sound",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1WhatMusicNow />,
    name: "Q1. What music now",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1End />,
    name: "Q1. End",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1ExcludeTopics />,
    name: "Q1. Exclude topics",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1PlaylistBeingCreated />,
    name: "Q1. Playlist being created",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question1WhatKindOfChange />,
    name: "Q1. What kind of change",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <PreparingYourFirstAudioAtHome />,
    name: "Preparing first audio",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    dispatchOnEnter: (state, dispatch) => {
      if (state.questionnaire.questionnaireOne === "INCOMPLETE") {
        dispatch(completedQuestionnaireOne());
      }
    },
  },
  {
    component: <PlantAnimation />,
    name: "Plant animation",
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: { audioCompletion: "introInPerson1" },
    },
    onExitAnimation: "SLIDE_LEFT",
    acoustics: {
      introInPerson1: makeAcousticConfig(),
      questionnaireMusic: makeAcousticConfig({
        secondsUntilFadeOut: 0.001,
        volume: 0.2,
      }),
    },
  },
  {
    component: <EnterVenue />,
    name: "Enter venue",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: { secondsToNextStep: 90, showProgressBar: true },
    },
    acoustics: {
      oldSong: makeAcousticConfig({ volume: 0.7 }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <HaveYouArrived />,
    name: "Have you arrived",
    allowNext: {
      allowNextOn: "MANUAL_CLICK",
      config: { footerButtonText: "YES" },
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <InPersonNarration2 />,
    name: "Narration #2",
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: { audioCompletion: "introInPerson2" },
    },
    acoustics: {
      oldSong: makeAcousticConfig({ secondsUntilFadeOut: 0.1, volume: 0.5 }),
      introInPerson2: makeAcousticConfig(),
      soothingTrack: makeAcousticConfig({
        volume: 0.2,
        fadeInSeconds: 100,
        secondsUntilFadeIn: 300,
      }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },

  {
    component: <SoothingSong />,
    name: "Soothing Song",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 16,
      },
    },
    acoustics: {
      soothingTrack: makeAcousticConfig({ volume: 0.2, fadeInSeconds: 5 }),
    },
  },
  {
    component: <Scene1Story1 />,
    name: "S1. Story 1",
    allowNext: { allowNextOn: "AUDIO_PLAYBACK_COMPLETE" },
    acoustics: {
      storySelectionOne: makeAcousticConfig(),
      soothingTrack: makeAcousticConfig({
        volume: 0.2,
        secondsUntilFadeOut: 0.001,
      }),
    },
  },
  {
    component: <Question2Intro />,
    name: "Q2. Intro",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    acoustics: {
      soothingTrack: makeAcousticConfig({ fadeInSeconds: 4, volume: 0.2 }),
      questionnaireMusic: makeAcousticConfig({
        fadeInSeconds: 10,
        volume: 0.5,
      }),
    },
  },
  {
    component: <Question2HowChangeFeel />,
    name: "Q2. How change feel",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2MakeChange />,
    name: "Q2. Make change",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2HowMuchChange />,
    name: "Q2. How much change",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2FormativePeriod />,
    name: "Q2. Formative period",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2WantThreeThings />,
    name: "Q2. Want three things",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2HateThreeThings />,
    name: "Q2. Hate three things",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2MostAfraid />,
    name: "Q2. Most afraid",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2Dependent />,
    name: "Q2. Dependent",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2Ignored />,
    name: "Q2. Ignored",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2IdentityIndependence />,
    name: "Q2. Identity & independence",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2Relationships />,
    name: "Q2. Relationships",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question2Family />,
    name: "Q2. Family",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <InPersonScene2Intro />,
    name: "Preparing next tracks",
    allowNext: { allowNextOn: "COUNTDOWN", config: { secondsToNextStep: 10 } },
    acoustics: {
      questionnaireMusic: makeAcousticConfig({ secondsUntilFadeOut: 0.001 }),
    },
    dispatchOnEnter: (state, dispatch) => {
      if (state.questionnaire.questionnaireTwo === "INCOMPLETE") {
        dispatch(completedQuestionnaireTwo());
      }
    },
  },
  {
    component: <Scene2Story1 />,
    name: "S2. Story one",
    allowNext: { allowNextOn: "AUDIO_PLAYBACK_COMPLETE" },
    acoustics: {
      story1: makeAcousticConfig(),
      soothingTrack: makeAcousticConfig({
        secondsUntilFadeOut: 10,
        volume: 0.2,
      }),
    },

    onExitAnimation: "SLIDE_LEFT",
  },

  {
    component: <InPersonNarration3 />,
    name: "Narration 3",
    allowNext: { allowNextOn: "AUDIO_PLAYBACK_COMPLETE" },
    acoustics: {
      sceneTwoNarration: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Scene2Story2 />,
    name: "S2. Story two",
    allowNext: { allowNextOn: "AUDIO_PLAYBACK_COMPLETE" },
    acoustics: {
      story2: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Checkin />,
    name: "Checkin",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 50,
      },
    },
    acoustics: {
      changeSong: makeAcousticConfig(),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Checkout />,
    name: "Checkout",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 20,
      },
    },
    acoustics: {
      changeSong: makeAcousticConfig({ secondsUntilFadeOut: 10 }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Question3Intro />,
    name: "Q3. Intro",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    acoustics: {
      soothingTrack: makeAcousticConfig({ fadeInSeconds: 5, volume: 0.2 }),
      questionnaireMusic: makeAcousticConfig({
        fadeInSeconds: 10,
        volume: 0.5,
      }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Question3InternalExternal />,
    name: "Q3. Internal external",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3WantMore />,
    name: "Q3. Want more",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3ActionsValues />,
    name: "Q3. Actions values",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3Pressing />,
    name: "Q3. Pressing",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3Impact />,
    name: "Q3. Impact",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3LifeWillBe />,
    name: "Q3. Life will be",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Question3Afraid />,
    name: "Q3. Afraid",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Scene3Intro />,
    name: "S3. Intro",
    allowNext: { allowNextOn: "MANUAL_CLICK" },
    dispatchOnEnter: (state, dispatch) => {
      if (state.questionnaire.questionnaireThree === "INCOMPLETE") {
        dispatch(completedQuestionnaireThree());
      }
    },
    acoustics: {
      soothingTrack: makeAcousticConfig({
        secondsUntilFadeOut: 0.001,
        volume: 0.2,
      }),
      questionnaireMusic: makeAcousticConfig({ secondsUntilFadeOut: 4 }),
    },
    onExitAnimation: "SLIDE_UP",
  },
  {
    component: <Scene3Story1 />,
    name: "S3. Story 1",
    allowNext: { allowNextOn: "AUDIO_PLAYBACK_COMPLETE" },
    acoustics: {
      changeStory: makeAcousticConfig(),
      soothingTrack: makeAcousticConfig({
        secondsUntilFadeOut: 0.001,
        volume: 0.1,
      }),
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <Scene3Narration />,
    name: "S3. Narration",
    allowNext: { allowNextOn: "COUNTDOWN", config: { secondsToNextStep: 75 } },
    acoustics: {
      lastNarration: makeAcousticConfig({ volume: 1 }),
    },
  },
  {
    component: <WordOfEncouragement />,
    name: "Word of encouragement",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 60,
      },
    },
  },
  {
    component: <RipPaper />,
    name: "Rip Paper",
    allowNext: {
      allowNextOn: "COMPONENT_DECIDES",
    },
  },
  {
    component: <JewelEffect />,
    name: "Jewel Light Look",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: { secondsToNextStep: 56, showProgressBar: false },
    },
    acoustics: {
      lastNarration2: makeAcousticConfig(),
    },
  },
  {
    component: <ReceivedWord />,
    name: "Received Words",
    onExitAnimation: "SLIDE_LEFT",
    acoustics: {
      lastNarration2: makeAcousticConfig(),
    },
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: { audioCompletion: "lastNarration2" },
    },
  },
  {
    component: <JewelLight2 />,
    name: "Jewel Light 2",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: { secondsToNextStep: 60 },
    },
    acoustics: {
      newMusic: makeAcousticConfig({ fadeInSeconds: 15 }),
    },
  },
  {
    component: <InPersonNiceSpendingTimeWithYou />,
    name: "Remaining music",
    acoustics: {
      newMusic: makeAcousticConfig(),
    },
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: { secondsToNextStep: 60 },
    },
  },
  {
    component: <InPersonNiceSpendingTime2 />,
    name: "Over to you",
    acoustics: {
      newMusic: makeAcousticConfig(),
    },
    allowNext: {
      allowNextOn: "AUDIO_PLAYBACK_COMPLETE",
      config: { audioCompletion: "newMusic" },
    },
  },
  {
    component: <Goodbye />,
    name: "Goodbye",
    allowNext: {
      allowNextOn: "COUNTDOWN",
      config: {
        secondsToNextStep: 10,
      },
    },
    onExitAnimation: "SLIDE_LEFT",
  },
  {
    component: <End />,
    name: "End",
    allowNext: {
      allowNextOn: "COMPONENT_DECIDES",
    },
  },
];
